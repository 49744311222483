export const routes = /** @type {const} */ ({
  home: "/",
  accountDeleted: "/account-deleted",
  deleteAccountConfirmation: "/delete-account-confirmation",
  deleteAccountError: "/delete-account-error",
  savedProjects: "/saved-projects",
  orders: "/orders",
  orderDetails: "/orders/:orderCode",
  photos: "/photos",
  photoPreview: "/photos/preview/:id",
  albums: "/albums",
  album: "/albums/:id",
  albumPhotoPreview: "/albums/:id/preview/:photoId",
  downloads: "/downloads",
  settings: "/settings",
  settingsB2b: "/settings/b2b",
  changeEmailB2b: "/settings/b2b/email",
  personalDetails: "/settings/personal-details",
  paymentMethod: "/settings/payment-method",
  changeEmail: "/settings/email",
  changeEmailConfirmation: "/change-email-confirmation",
  changeEmailSuccess: "/change-email-success",
  changeEmailError: "/change-email-error",
  logout: "/logout",
  widgets: "/online-products",
  widgetShare: "/online-products/:productCode",
  addAddress: "/settings/new-address",
  changeAddress: "/settings/address/",
  allAddresses: "/settings/addresses/all",
  invoices: "/invoices",
  ftpIntegration: "/integration",
  addAddressB2b: "/settings/b2b/new-address",
  changeAddressB2b: "/settings/b2b/address/",
  doubleOptInConfirmation: "/subscription-confirmation",
  doubleOptInConfirmationSuccess: "/subscription-confirmation-success",
  doubleOptInConfirmationError: "/subscription-confirmation-error"
});
